/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
/* styles.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.invalid-feedback{
    color:red
}
.is-invalid{
    border-color: red;
}

.cardWithShadow {
    box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.02) 0px 12px 24px -4px;
    /* rgb(145 158 171 / 30%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px; */

}

.table-responsive {
    overflow-x: auto;
}

.table-responsive td,
.table-responsive mat-cell {
    white-space: nowrap;
    padding: 16px;
}

.mat-card-title {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.mat-card-actions {
    border-top: 1px solid rgba(120, 130, 140, 0.13);
    padding: 8px 0 !important;
}
.mat-dialog-container{
    padding: 16px !important;
    overflow: hidden !important;
}
.mat-dialog-content{
    padding: 0 !important;
    margin: 0 !important;
    overflow-x: hidden !important;
}
 /* These below 2 classes are for removing that outline color that gets append to every element automatically as the were received in base.css from shakeitprot cdn file*/
*:focus-visible {
    outline: none !important;
    outline-offset: 0 !important;
    box-shadow: none !important;
  }
  .focused,
.no-js *:focus {
  outline: none !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
}
.mat-dialog-title {
    display: block !important;
    text-align: center;
}
.status_button.process {
    color: #fff;
    background-color: rgb(234, 234, 150);
}

.status_button.processed {
    background-color: rgb(28, 175, 28);
}

.status_button.Unprocessed {
    background-color: red;
}


input {
    border-radius: 3rem;
    border: 1px solid #9ca3af;
    padding: 1rem 2rem;
    background: transparent;
}

select {
    border-radius: 3rem;
    border: 1px solid #9ca3af;
    padding: 1rem 2rem;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

textarea {
    border-radius: 3rem;
    border: 1px solid #9ca3af;
    padding: 1rem 2rem;
    background: transparent;
}
button {
    border-radius: 3rem;
    padding: 1rem 2rem;
}


::ng-deep .mat-form-field-outline-start {
    border-radius: 28px 0 0 28px !important;
    min-width: 28px !important;
}

::ng-deep .mat-form-field-outline-end {
    border-radius: 0 28px 28px 0 !important;
    min-width: 28px !important;
}

/* Add padding to move the label and input into the center to prevent overlapping the rounded border */
::ng-deep .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 32px !important;
}